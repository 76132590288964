<script setup>

const blend = useBlend();

const props = defineProps({
  step: {
    type: Number,
    default: 2
  },
  withBack: Boolean
})

const popupVisible = ref(false);
const refNextButton = ref();

const setBusy = () => refNextButton.value.setBusy();
const setNone = () => refNextButton.value.setNone();

defineExpose({
  setBusy,
  setNone
})

const emits = defineEmits(['submit', 'back']);

blend.blendEffect(popupVisible, false, true, true,
  () => {
    popupVisible.value = false;
  }
)

const steps = [
  {
    label: "Wybór samochodu",
    canChangeCar: true
  },
  {
    label: "Dane kontaktowe",
    canChangeCar: true
  },
  {
    label: "Parametry finansowania",
    canChangeCar: true
  },
  {
    label: "Twoja oferta",
  },
  {
    label: "Dane do wniosku",
    value: "5"
  },
  // {
  //   label: "Potwierdzenie danych",
  // },
  {
    label: "Wstępna decyzja",
  },
  {
    label: "Podpisanie umowy",
  }
]
</script>

<template>
  <div class="flex py-6 items-center flex-wrap md:flex-nowrap">
    <PopupCall :visible="popupVisible" @dismiss="popupVisible = false" />
    <div class="md:w-full flex justify-start grow order-3 md:order-1 pt-4 md:pt-0">
      <ButtonPlain class="font-extralight text-xs text-gray-700" @click="emits('back',props.step)"
      v-show="props.withBack">
        <template v-slot:leftSlot>
          <IconChevronLeft class="!w-2 !h-2" />
        </template>
        <template v-slot:default>wstecz</template>
      </ButtonPlain>
    </div>
    <div class="order-1 md:order-2 text-xs font-extralight whitespace-nowrap flex flex-col items-end basis-1/2 md:basis-0 pr-4">
      <div>Kolejny krok:</div>
      <div class="font-light">{{ steps[step].label }}</div>
    </div>
    <div class="order-2 md:order-3 basis-1/2 md:basis-0">
      <ButtonRounded ref="refNextButton"
                     @click="emits('submit',props.step)"
                     class="!min-w-48"
                     color="gray">Dalej
      </ButtonRounded>
    </div>
    <div class="order-4 pt-4 md:pt-0">
      <ButtonPlain class="text-sm whitespace-nowrap pl-4" @click="popupVisible = true" >
        <template v-slot:leftSlot>
          <IconChat class="!w-5 !h-5" />
        </template>
        <template v-slot:default>Pomoc konsultanta</template>
      </ButtonPlain>
    </div>
  </div>
</template>
