<script setup>
import FormContact from "~/components/DigitalOrder/FormContact.vue";
import FormFinancing from "~/components/DigitalOrder/FormFinancing.vue";
import OfferPreview from "~/components/DigitalOrder/OfferPreview.vue";
import FormApplication from "~/components/DigitalOrder/FormApplication.vue";
import FormPsd2 from "~/components/DigitalOrder/FormPsd2.vue";
import PreliminaryDecision from "~/components/DigitalOrder/PreliminaryDecision.vue";
import NagCongratulations from "~/components/DigitalOrder/NagCongratulations.vue";

import NagDataVerification from "~/components/DigitalOrder/NagDataVerification.vue";
import { useDigitalOrder } from "~/composables/DigitalOrder";

const forms = [
  FormContact,
  FormFinancing,
  OfferPreview,
  FormApplication,
  // FormPsd2,
  PreliminaryDecision,
  NagCongratulations
]

const nags = [
  NagDataVerification,
]

const salesManago = useSalesManago();

const refActualStepForm = ref();
const refNavigation = ref();
const somethingWentWrong = ref(false);
const visibleNavigation = ref(true);

definePageMeta({
  layout: 'minimal',
})

const digitalOrder = useDigitalOrder();
const imageOnScroll = computed(() => digitalOrder.actualStep.value === 4 ? 300 : 0)

const submit = async () => {
  const response = refActualStepForm.value.submit();
  if (response) {
    refNavigation.value.setBusy();
    const result = await digitalOrder.handleSteps(response);

    refNavigation.value.setNone();
    if (result === false) {
      refActualStepForm.value.setErrors(digitalOrder.getErrors());
      return;
    }

    if (result === null) {
      somethingWentWrong.value = true;
      return;
    }

    somethingWentWrong.value = false;

    if (digitalOrder.actualStep.value === 6) {
      // omitting psd2 by now
      digitalOrder.actualStep.value += 2;
      return
    }
    digitalOrder.sendAnalyticsByStep(digitalOrder.actualStep.value);
    digitalOrder.actualStep.value++;

  }
}

const back = () => {
  if (digitalOrder.actualStep.value === 2) {
    return;
  }

  if (digitalOrder.actualStep.value === 8) {
    // omitting psd2 by now
    digitalOrder.actualStep.value -= 2;
    return
  }

  digitalOrder.actualStep.value--;
}

await digitalOrder.loadOrder();

useHead({
  title: "Vehis.pl - atrakcyjny leasing samochodów nowych i używanych",
  meta: [
    {name: 'description', content: 'Leasing samochodów z atrakcyjnymi warunkami finansowania  u lidera w branży ✔️ Decyzja w 15 min. Sprawdź!'}
  ],
  link: [
    {name: 'robots', content: 'noindex, follow'},
    {rel: 'canonical', href: 'https://vehis.pl'}
  ]
});

onMounted( () => salesManago.sendEvent('TRANSACTION', digitalOrder.car.value.group_id))

</script>

<template>
  <ClientOnly>
    <Container no-paddings>
      <DigitalOrderStepsIndicator :step="digitalOrder.actualStep.value" />
      <LayoutColumns>
        <LayoutMainColumn noflex>
          <Component ref="refActualStepForm"
                     :is="forms[digitalOrder.actualStep.value - 2]"
                     :car="digitalOrder.car.value"
                     @navigationControl="(v) => visibleNavigation = v"
          />
          <DigitalOrderNavigation v-show="visibleNavigation"
                                  ref="refNavigation"
                                  :step="digitalOrder.actualStep.value"
                                  @submit="submit"
                                  @back="back"
                                  :withBack="digitalOrder.actualStep.value > 2"
          />
        </LayoutMainColumn>
        <LayoutSideColumn class="!bg-transparent pb-8">
          <CarOfferFloatingBox v-if=digitalOrder.car.value :car="digitalOrder.car.value"
                               class="hidden lg:block pt-0"
                               :class="{'pt-4':imageOnScroll}"
                               :imageOnScroll="imageOnScroll"
                               no-button no-adjust />
        </LayoutSideColumn>
      </LayoutColumns>
    </Container>
  </ClientOnly>
</template>
